
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/inspection";
const dsBridge = require("dsbridge");

@Options({})
export default class extends Vue {
  private initData: any[] = [];
  mounted() {
    //微信配置
    api
      .mobileInspectionGetTicket({
        url: window.location.href.split("#")[0],
      })
      .then((res: any) => {
        if (!res.code) {
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appid, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: ["scanQRCode"], // 必填，需要使用的JS接口列表
          });
        }
      });
    //安卓配置
    dsBridge.register("cameraScanResult", this.afterScan);
    this.initView();
  }

  initView() {
    api.listPointAll(this.$route.query).then((res) => {
      this.initData = res.data;
    });
  }

  protected scan() {
    if (dsBridge.hasNativeMethod("getSharedPreferences")) {
      dsBridge.call("cameraScan", "");
    } else {
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: (res: any) => {
          this.afterScan(res.resultStr);
        },
      });
    }
  }

  private afterScan(result: string) {
    api
      .mobileInspectionDecode({
        code: result,
        wayId: this.$route.query.wayId,
        projectId: this.$store.state.project.projectinfo.projectId,
      })
      .then((res: any) => {
        if (res.code === 0) {
          this.$router.push({
            name: "inspectionsaveWay",
            query: {
              wayId: this.$route.query.wayId,
              pointId: res.data,
            },
          });
        } else {
          this.$toast.fail({
            message: res.msg,
          });
        }
      })
      .catch((error) => {
        this.$toast.fail("网络异常");
      });
  }
}
